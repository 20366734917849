.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f5f5f5;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: "ProtoMono";
  src: url(./fonts/ProtoMonoRegular.ttf);
}

body {
  font-family: "ProtoMono";
  background: #00091B;
}


/* Hide the scrollbar for webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0rem;
  /* Adjust the width as needed */
  background-color: transparent;
  /* Set the background color of the scrollbar track */
}

/* Hide the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Set the background color of the scrollbar thumb */
}

/* Hide the scrollbar track */
::-webkit-scrollbar-track {
  background-color: transparent;
  /* Set the background color of the scrollbar track */
}

::selection {
  background-color: #f5f5f5;
  /* Set the desired highlight color */
  color: #00091B;
  /* Set the desired text color for highlighted text */
}